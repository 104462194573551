<template>
  <div>
    <b-row class="pt-1">
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>DOMAIN APLIKASI</h5>
        <p>{{ dataAplikasi.domain_aplikasi }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>AREA APLIKASI</h5>
        <p>{{ dataAplikasi.area_aplikasi }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NAMA APLIKASI</h5>
        <p>{{ dataAplikasi.nama_aplikasi }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>STATUS KEGIATAN</h5>
        <p>{{ dataAplikasi.status_kegiatan }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>TOTAL ANGGARAN</h5>
        <p>{{ dataAplikasi.total_anggaran ? Number(dataAplikasi.total_anggaran).toLocaleString() : 0 }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>FUNGSI APLIKASI</h5>
        <p>{{ dataAplikasi.fungsi }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>LUARAN / OUTPUT</h5>
        <p>{{ dataAplikasi.output }}</p>
      </b-col>
      <b-col
        v-if="dataLayanan"
        md="6"
        class="mb-50"
      >
        <h5>LAYANAN YANG DIDUKUNG</h5>
        <p>{{ dataLayanan.area_layanan }}</p>
      </b-col>
      <b-col
        v-if="dataInfo"
        md="6"
        class="mb-50"
      >
        <h5>DATA YANG DIGUNAKAN</h5>
        <p>{{ dataInfo.data_tematik }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>BASIS APLIKASI</h5>
        <p>
          <b-badge
            v-for="(data, index) in dataAplikasi.basis_aplikasi"
            :key="index"
            class="mr-25 mb-25"
          >
            {{ (data.nama) ? data.nama : data }}
          </b-badge>
        </p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>SERVER APLIKASI</h5>
        <p>{{ dataAplikasi.server }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>TIPE LISENSI BAHASA PEMROGRAMAN</h5>
        <p>{{ dataAplikasi.lisensi_aplikasi }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>BAHASA PEMROGRAMAN</h5>
        <p>
          <b-badge
            v-for="(data, index) in dataAplikasi.bahasa_pemrograman_aplikasi"
            :key="index"
            class="mr-25 mb-25"
          >
            {{ (data.nama) ? data.nama : data }}
          </b-badge>
        </p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>KERANGKA/FRAMEWORK PENGEMBANGAN</h5>
        <p>
          <b-badge
            v-for="(data, index) in dataAplikasi.framework_aplikasi"
            :key="index"
            class="mr-25 mb-25"
          >
            {{ (data.nama) ? data.nama : data }}
          </b-badge>
        </p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>BASIS DATA</h5>
        <p>
          <b-badge
            v-for="(data, index) in dataAplikasi.basidata_aplikasi"
            :key="index"
            class="mr-25 mb-25"
          >
            {{ (data.nama) ? data.nama : data }}
          </b-badge>
        </p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>UNIT PENGEMBANG</h5>
        <p>{{ dataAplikasi.unit_pengembang }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>UNIT OPERASIONAL TEKNOLOGI</h5>
        <p>{{ dataAplikasi.unit_operasional_teknologi }}</p>
      </b-col>
      <b-col
        v-if="dataAplikasi.file_kak !== null"
        md="6"
        class="mb-50"
      >
        <h5>Kerangka Acuan Kerja</h5>
        <p>
          <b-button
            variant="success"
            size="sm"
            class="mr-25 mb-25"
            @click="openKomponen(dataAplikasi.kak_uri)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(dataAplikasi.kak_uri)"
            class="btn btn-sm btn-info mr-25 mb-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            /></b-link>
          <small>{{ dataAplikasi.kak_uri.substring(dataAplikasi.kak_uri.lastIndexOf('/') + 1) }}</small>
        </p>
      </b-col>
      <b-col
        v-if="dataAplikasi.additional_docs.length !== 0"
        md="6"
        class="mb-50"
      >
        <h5>Dokumen Tambahan</h5>
        <p
          v-for="(data, index) in dataAplikasi.additional_docs"
          :id="`dt-${index}`"
          :key="index"
        >
          <b-button
            variant="success"
            size="sm"
            class="mr-25 mb-25"
            @click="openKomponen(data.document_uri)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(data.document_uri)"
            class="btn btn-sm btn-info mr-25 mb-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            />
          </b-link>
          <small>{{ data.document_uri.substring(data.document_uri.lastIndexOf('/') + 1) }}</small>
        </p>
      </b-col>
      <b-col md="12">
        <h5>URAIAN APLIKASI</h5>
        <p>{{ dataAplikasi.uraian_aplikasi }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BBadge, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BBadge,
    BLink,
  },
  props: {
    dataAplikasi: {
      type: Object,
      default: () => {},
    },
    dataInfo: {
      type: Object,
      default: () => {},
    },
    dataLayanan: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openKomponen(fileName) {
      this.$emit('view-file', fileName)
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
  },
}
</script>
